import React from "react";
import { GraphQLProvider, ThemeProvider, ToastProvider } from "@webomat/webomat-ui-react/build/esm";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "./tailwind.config";
import { AiChatAssistant } from "./components/ai-chat-assistant/ai-chat-assistant";
import { cache } from "@/utils/graphql";
import { IntlProvider } from "./providers/intl-provider";
import { useSearchParams } from "react-router-dom";

function App() {
    const fullConfig = resolveConfig(tailwindConfig);
    const theme = fullConfig.theme;
    const [searchParams] = useSearchParams();
    const color = searchParams.get("color") as never;
    const lang = searchParams.get("lang") as never;
    const renderMethod = searchParams.get("renderMethod") as never;
    const buttonLabel = searchParams.get("buttonLabel") as never;
    const position = searchParams.get("position") as never;
    const demo = searchParams.get("demo") as never;
    const open = searchParams.has("open") && searchParams.get("open") !== "false" ? true : false;

    return (
        <ThemeProvider theme={theme}>
            <ToastProvider>
                <IntlProvider locale={lang || "en"}>
                    <GraphQLProvider cache={cache} lang={lang || "en"} uri={`${process.env.REACT_APP_API_URL}/graphql`}>
                        <AiChatAssistant
                            id="gid://shopify/Shop/85531820356"
                            color={color ? `#${color}` : undefined}
                            lang={lang || "en"}
                            renderMethod={renderMethod || "button"}
                            buttonLabel={buttonLabel || undefined}
                            position={position || "right"}
                            demo={demo || undefined}
                            open={open}
                        />
                    </GraphQLProvider>
                </IntlProvider>
            </ToastProvider>
        </ThemeProvider>
    );
}

export default App;
