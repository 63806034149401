export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  DateTimeUtc: { input: any; output: any; }
  Mixed: { input: any; output: any; }
  UUID: { input: string; output: string; }
  Upload: { input: any; output: any; }
};

export type GQLAddress = {
  __typename?: 'Address';
  address1: Maybe<Scalars['String']['output']>;
  address2: Maybe<Scalars['String']['output']>;
  alias: Scalars['String']['output'];
  city: Maybe<Scalars['String']['output']>;
  company: Maybe<Scalars['String']['output']>;
  companyRegisterNumber: Maybe<Scalars['String']['output']>;
  doorNumber: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  firstname: Maybe<Scalars['String']['output']>;
  floor: Maybe<Scalars['String']['output']>;
  gender: Maybe<GQLGender>;
  id: Scalars['ID']['output'];
  lastname: Maybe<Scalars['String']['output']>;
  legalForm: Maybe<Scalars['String']['output']>;
  localCourt: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  salesTax: Maybe<Scalars['String']['output']>;
  stairs: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  web: Maybe<Scalars['String']['output']>;
  zip: Maybe<Scalars['String']['output']>;
};

export type GQLApp = {
  __typename?: 'App';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQLAssistant = {
  __typename?: 'Assistant';
  accessToken: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  answerLength: GQLAssistantAnswerLength;
  app: GQLApp;
  avatar: Maybe<GQLFile>;
  buttonLabel: Maybe<Scalars['Mixed']['output']>;
  color: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  data: Maybe<GQLOpenaiAssistant>;
  domains: Maybe<Array<Scalars['String']['output']>>;
  emojiUsage: GQLAssistantEmojiUsage;
  files: Maybe<Array<GQLOpenaiAssistantFile>>;
  humor: GQLAssistantHumor;
  id: Scalars['ID']['output'];
  languageTone: GQLAssistantLanguageTone;
  name: Maybe<Scalars['String']['output']>;
  placeholder: Maybe<Scalars['Mixed']['output']>;
  position: GQLAssistantIconPosition;
  predefinedMessage1: Maybe<Scalars['Mixed']['output']>;
  predefinedMessage2: Maybe<Scalars['Mixed']['output']>;
  predefinedMessage3: Maybe<Scalars['Mixed']['output']>;
  predefinedMessage4: Maybe<Scalars['Mixed']['output']>;
  predefinedMessage5: Maybe<Scalars['Mixed']['output']>;
  project: GQLProject;
  role: GQLAssistantRole;
  routing: GQLAssistantRouting;
  subtitle: Maybe<Scalars['Mixed']['output']>;
  temporaryInactive: Scalars['Boolean']['output'];
  title: Maybe<Scalars['Mixed']['output']>;
  tokenUsage: GQLTokenUsage;
  updatedAt: Scalars['DateTime']['output'];
  user: GQLUser;
  welcomeMessage: Maybe<Scalars['Mixed']['output']>;
};


export type GQLAssistantButtonLabelArgs = {
  raw?: InputMaybe<Scalars['Boolean']['input']>;
};


export type GQLAssistantPlaceholderArgs = {
  raw?: InputMaybe<Scalars['Boolean']['input']>;
};


export type GQLAssistantPredefinedMessage1Args = {
  raw?: InputMaybe<Scalars['Boolean']['input']>;
};


export type GQLAssistantPredefinedMessage2Args = {
  raw?: InputMaybe<Scalars['Boolean']['input']>;
};


export type GQLAssistantPredefinedMessage3Args = {
  raw?: InputMaybe<Scalars['Boolean']['input']>;
};


export type GQLAssistantPredefinedMessage4Args = {
  raw?: InputMaybe<Scalars['Boolean']['input']>;
};


export type GQLAssistantPredefinedMessage5Args = {
  raw?: InputMaybe<Scalars['Boolean']['input']>;
};


export type GQLAssistantSubtitleArgs = {
  raw?: InputMaybe<Scalars['Boolean']['input']>;
};


export type GQLAssistantTitleArgs = {
  raw?: InputMaybe<Scalars['Boolean']['input']>;
};


export type GQLAssistantWelcomeMessageArgs = {
  raw?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GQLAssistantAnswerLength {
  Default = 'default',
  Long = 'long',
  Short = 'short'
}

export type GQLAssistantCustomRoutingObject = {
  __typename?: 'AssistantCustomRoutingObject';
  contact: Maybe<Array<Maybe<GQLAssistantRoutingContact>>>;
  instructions: Maybe<Scalars['String']['output']>;
  prompt: Scalars['String']['output'];
};

export type GQLAssistantCustomRoutingObjectInput = {
  contact?: InputMaybe<Array<InputMaybe<GQLAssistantRoutingContactInput>>>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  prompt: Scalars['String']['input'];
};

export enum GQLAssistantEmojiUsage {
  Avoid = 'avoid',
  Default = 'default',
  Few = 'few',
  Many = 'many'
}

export enum GQLAssistantHumor {
  Avoid = 'avoid',
  Default = 'default',
  Heavy = 'heavy',
  Light = 'light'
}

export enum GQLAssistantIconPosition {
  Left = 'left',
  Right = 'right'
}

export enum GQLAssistantLanguageTone {
  Formal = 'formal',
  Informal = 'informal'
}

/** A paginated list of Assistant items. */
export type GQLAssistantPaginator = {
  __typename?: 'AssistantPaginator';
  /** A list of Assistant items. */
  data: Array<GQLAssistant>;
  /** Pagination information about the list of items. */
  paginatorInfo: GQLPaginatorInfo;
};

export type GQLAssistantRole = {
  __typename?: 'AssistantRole';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQLAssistantRouting = {
  __typename?: 'AssistantRouting';
  custom: Maybe<Array<Maybe<GQLAssistantCustomRoutingObject>>>;
  fallback: Maybe<GQLAssistantRoutingObject>;
  orders: Maybe<GQLAssistantRoutingObject>;
  products: Maybe<GQLAssistantRoutingObject>;
};

export type GQLAssistantRoutingContact = {
  __typename?: 'AssistantRoutingContact';
  type: GQLAssistantRoutingContactType;
  value: Scalars['String']['output'];
};

export type GQLAssistantRoutingContactInput = {
  type?: InputMaybe<GQLAssistantRoutingContactType>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum GQLAssistantRoutingContactType {
  Email = 'email',
  Phone = 'phone',
  Url = 'url',
  WhatsApp = 'whatsApp'
}

export type GQLAssistantRoutingInput = {
  custom?: InputMaybe<Array<InputMaybe<GQLAssistantCustomRoutingObjectInput>>>;
  fallback?: InputMaybe<GQLAssistantRoutingObjectInput>;
  orders?: InputMaybe<GQLAssistantRoutingObjectInput>;
  products?: InputMaybe<GQLAssistantRoutingObjectInput>;
};

export type GQLAssistantRoutingObject = {
  __typename?: 'AssistantRoutingObject';
  contact: Maybe<Array<Maybe<GQLAssistantRoutingContact>>>;
  instructions: Maybe<Scalars['String']['output']>;
};

export type GQLAssistantRoutingObjectInput = {
  contact?: InputMaybe<Array<InputMaybe<GQLAssistantRoutingContactInput>>>;
  instructions?: InputMaybe<Scalars['String']['input']>;
};

export type GQLAssistantsFilter = {
  appId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQLCountry = {
  __typename?: 'Country';
  id: Scalars['ID']['output'];
};

export type GQLFile = {
  __typename?: 'File';
  extension: GQLFileExtension;
  filename: Scalars['String']['output'];
  filesize: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sizes: Maybe<Array<GQLImageSize>>;
  thumbnail: Scalars['String']['output'];
  token: Scalars['String']['output'];
  type: GQLFileType;
};


export type GQLFileSizesArgs = {
  sizes?: InputMaybe<Array<GQLImageSizeInput>>;
};

export enum GQLFileExtension {
  Jpg = 'jpg',
  Pdf = 'pdf',
  Png = 'png',
  Webp = 'webp'
}

export enum GQLFileType {
  Audio = 'audio',
  Document = 'document',
  Image = 'image',
  Video = 'video'
}

export enum GQLGender {
  Divers = 'divers',
  Female = 'female',
  Male = 'male'
}

export type GQLImageSize = {
  __typename?: 'ImageSize';
  height: Maybe<Scalars['Int']['output']>;
  src: Scalars['String']['output'];
  width: Maybe<Scalars['Int']['output']>;
};

export type GQLImageSizeInput = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type GQLLangKeyInput = {
  de?: InputMaybe<Scalars['String']['input']>;
  en?: InputMaybe<Scalars['String']['input']>;
  es?: InputMaybe<Scalars['String']['input']>;
  fr?: InputMaybe<Scalars['String']['input']>;
  it?: InputMaybe<Scalars['String']['input']>;
};

export type GQLMessage = {
  __typename?: 'Message';
  content: Array<GQLMessageContent>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  role: GQLMessageRole;
  run: Maybe<GQLRun>;
};

export type GQLMessageContent = {
  __typename?: 'MessageContent';
  text: Maybe<GQLMessageContentText>;
  type: GQLMessageContentType;
};

export type GQLMessageContentText = {
  __typename?: 'MessageContentText';
  value: Scalars['String']['output'];
};

export enum GQLMessageContentType {
  Text = 'text'
}

export enum GQLMessageRole {
  Assistant = 'assistant',
  User = 'user'
}

export enum GQLMoveDirection {
  Down = 'down',
  Up = 'up'
}

export type GQLMutation = {
  __typename?: 'Mutation';
  /** Create Assistant */
  createAssistant: Maybe<GQLResponseMutateAssistant>;
  /** Create Message */
  createMessage: Maybe<GQLResponseMutateMessage>;
  /** Create Project */
  createProject: Maybe<GQLResponseMutateProject>;
  /** Create Shopify Session */
  createShopifySession: Maybe<GQLResponseMutateShopifySession>;
  /** Create Shopify User */
  createShopifyUser: GQLResponseAuth;
  /** New registration */
  createUser: GQLResponseAuth;
  /** Delete Shopify Session(s) */
  deleteShopifySessions: Maybe<GQLResponseDeleteShopifySession>;
  /** Login */
  login: GQLResponseAuth;
  /** Logout */
  logout: GQLResponseAuth;
  /** Request a link for password reset */
  requestPassword: Maybe<GQLResponseResetPassword>;
  /** Reset password */
  resetPassword: Maybe<GQLResponseResetPassword>;
  /** Select Project */
  selectProject: Maybe<GQLResponseMutateProject>;
  /** Sync Assistant Records */
  syncAssistantRecords: Maybe<GQLResponseMutateAssistant>;
  /** Update Assistant */
  updateAssistant: Maybe<GQLResponseMutateAssistant>;
  /** Upload Assistant File */
  uploadAssistantFile: Maybe<GQLResponseMutateAssistant>;
  /** Upload File */
  uploadFile: Maybe<GQLResponseMutateMedia>;
};


export type GQLMutationCreateAssistantArgs = {
  appId: Scalars['ID']['input'];
  assistantGroupId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};


export type GQLMutationCreateMessageArgs = {
  assistantId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  threadId: Scalars['ID']['input'];
};


export type GQLMutationCreateProjectArgs = {
  name: Scalars['String']['input'];
};


export type GQLMutationCreateShopifySessionArgs = {
  session: GQLShopifySessionInput;
};


export type GQLMutationCreateShopifyUserArgs = {
  email: Scalars['String']['input'];
  myshopifyDomain: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  primaryDomain: Scalars['String']['input'];
  ref: Scalars['String']['input'];
  terms: Scalars['Boolean']['input'];
};


export type GQLMutationCreateUserArgs = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  ref?: InputMaybe<Scalars['String']['input']>;
  terms: Scalars['Boolean']['input'];
};


export type GQLMutationDeleteShopifySessionsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type GQLMutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type GQLMutationRequestPasswordArgs = {
  email: Scalars['String']['input'];
};


export type GQLMutationResetPasswordArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type GQLMutationSelectProjectArgs = {
  id: Scalars['ID']['input'];
};


export type GQLMutationSyncAssistantRecordsArgs = {
  data: Array<GQLPineconeRecord>;
  id: Scalars['ID']['input'];
};


export type GQLMutationUpdateAssistantArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  answerLength?: InputMaybe<GQLAssistantAnswerLength>;
  assistantRole?: InputMaybe<Scalars['Int']['input']>;
  avatar?: InputMaybe<Scalars['ID']['input']>;
  buttonLabel?: InputMaybe<GQLLangKeyInput>;
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  emojiUsage?: InputMaybe<GQLAssistantEmojiUsage>;
  fields: Array<Scalars['String']['input']>;
  humor?: InputMaybe<GQLAssistantHumor>;
  id: Scalars['ID']['input'];
  instructions?: InputMaybe<Scalars['String']['input']>;
  languageTone?: InputMaybe<GQLAssistantLanguageTone>;
  name?: InputMaybe<Scalars['String']['input']>;
  placeholder?: InputMaybe<GQLLangKeyInput>;
  position?: InputMaybe<GQLAssistantIconPosition>;
  predefinedMessage1?: InputMaybe<GQLLangKeyInput>;
  predefinedMessage2?: InputMaybe<GQLLangKeyInput>;
  predefinedMessage3?: InputMaybe<GQLLangKeyInput>;
  predefinedMessage4?: InputMaybe<GQLLangKeyInput>;
  predefinedMessage5?: InputMaybe<GQLLangKeyInput>;
  routing?: InputMaybe<GQLAssistantRoutingInput>;
  subtitle?: InputMaybe<GQLLangKeyInput>;
  title?: InputMaybe<GQLLangKeyInput>;
  welcomeMessage?: InputMaybe<GQLLangKeyInput>;
};


export type GQLMutationUploadAssistantFileArgs = {
  assistantId: Scalars['ID']['input'];
  file: Scalars['Upload']['input'];
};


export type GQLMutationUploadFileArgs = {
  file: Scalars['Upload']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type GQLOpenaiAssistant = {
  __typename?: 'OpenaiAssistant';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instructions: Maybe<Scalars['String']['output']>;
};

export type GQLOpenaiAssistantFile = {
  __typename?: 'OpenaiAssistantFile';
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

/** Allows ordering a list of records. */
export type GQLOrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String']['input'];
  /** The direction that is used for ordering. */
  order: GQLSortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum GQLOrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum GQLOrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Information about pagination using a fully featured paginator. */
export type GQLPaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int']['output'];
  /** Index of the current page. */
  currentPage: Scalars['Int']['output'];
  /** Index of the first item in the current page. */
  firstItem: Maybe<Scalars['Int']['output']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean']['output'];
  /** Index of the last item in the current page. */
  lastItem: Maybe<Scalars['Int']['output']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int']['output'];
  /** Number of items per page. */
  perPage: Scalars['Int']['output'];
  /** Number of total available items. */
  total: Scalars['Int']['output'];
};

export type GQLPermission = {
  __typename?: 'Permission';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum GQLPineconeGroup {
  Collections = 'collections',
  Orders = 'orders',
  Products = 'products'
}

export type GQLPineconeRecord = {
  group: GQLPineconeGroup;
  id: Scalars['String']['input'];
  input: Scalars['String']['input'];
  metadata: Scalars['Mixed']['input'];
};

export type GQLProject = {
  __typename?: 'Project';
  active: Maybe<Scalars['Boolean']['output']>;
  address: Maybe<GQLAddress>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  logo: Maybe<GQLFile>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQLQuery = {
  __typename?: 'Query';
  /** Get Assistant By ID */
  assistant: Maybe<GQLAssistant>;
  /** Get Assistants */
  assistants: GQLAssistantPaginator;
  /** Get Logged User */
  me: Maybe<GQLUser>;
  /** Get Public Assistant By ID */
  publicAssistant: Maybe<GQLAssistant>;
  /** Get Run of a Thread */
  run: Maybe<GQLRun>;
  /** Get Shopify Session By ID */
  shopifySession: Maybe<GQLShopifySession>;
  /** Get Shopify Sessions By Shop */
  shopifySessionsByShop: Maybe<Array<Maybe<GQLShopifySession>>>;
  /** Get Thread By ID. If no `id` has been provided, a new thread will be created */
  thread: Maybe<GQLThread>;
};


export type GQLQueryAssistantArgs = {
  id: Scalars['ID']['input'];
};


export type GQLQueryAssistantsArgs = {
  filter?: InputMaybe<GQLAssistantsFilter>;
  first?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  sortColumn?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<GQLSortDirection>;
};


export type GQLQueryPublicAssistantArgs = {
  assistantId: Scalars['String']['input'];
};


export type GQLQueryRunArgs = {
  assistantId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  threadId: Scalars['ID']['input'];
};


export type GQLQueryShopifySessionArgs = {
  id: Scalars['String']['input'];
};


export type GQLQueryShopifySessionsByShopArgs = {
  shop: Scalars['String']['input'];
};


export type GQLQueryThreadArgs = {
  assistantId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type GQLResponseAuth = {
  __typename?: 'ResponseAuth';
  status: GQLResponseStatus;
  token: Maybe<Scalars['String']['output']>;
  user: Maybe<GQLUser>;
};

export type GQLResponseDeleteShopifySession = {
  __typename?: 'ResponseDeleteShopifySession';
  status: GQLResponseStatus;
};

export type GQLResponseMutateAssistant = {
  __typename?: 'ResponseMutateAssistant';
  assistant: GQLAssistant;
  status: GQLResponseStatus;
};

export type GQLResponseMutateMedia = {
  __typename?: 'ResponseMutateMedia';
  file: GQLFile;
  status: GQLResponseStatus;
};

export type GQLResponseMutateMessage = {
  __typename?: 'ResponseMutateMessage';
  message: GQLMessage;
  status: GQLResponseStatus;
};

export type GQLResponseMutateProject = {
  __typename?: 'ResponseMutateProject';
  project: GQLProject;
  status: GQLResponseStatus;
};

export type GQLResponseMutateShopifySession = {
  __typename?: 'ResponseMutateShopifySession';
  shopifySession: GQLShopifySession;
  status: GQLResponseStatus;
};

export type GQLResponseResetPassword = {
  __typename?: 'ResponseResetPassword';
  status: GQLResponseStatus;
};

export enum GQLResponseStatus {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning'
}

export type GQLRole = {
  __typename?: 'Role';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions: Maybe<Array<Maybe<GQLPermission>>>;
};

export type GQLRun = {
  __typename?: 'Run';
  id: Scalars['ID']['output'];
  status: GQLRunStatus;
};

export enum GQLRunStatus {
  Cancelled = 'cancelled',
  Cancelling = 'cancelling',
  Completed = 'completed',
  Expired = 'expired',
  Failed = 'failed',
  InProgress = 'inProgress',
  Incomplete = 'incomplete',
  Queued = 'queued',
  RequiresAction = 'requiresAction'
}

export type GQLShopifySession = {
  __typename?: 'ShopifySession';
  accessToken: Scalars['String']['output'];
  accountOwner: Scalars['Boolean']['output'];
  collaborator: Scalars['Boolean']['output'];
  email: Maybe<Scalars['String']['output']>;
  emailVerified: Scalars['Boolean']['output'];
  expires: Maybe<Scalars['DateTime']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isOnline: Scalars['Boolean']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  locale: Maybe<Scalars['String']['output']>;
  scope: Maybe<Scalars['String']['output']>;
  shop: Scalars['String']['output'];
  state: Scalars['String']['output'];
  userId: Maybe<Scalars['Int']['output']>;
};

export type GQLShopifySessionInput = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  accountOwner?: InputMaybe<Scalars['Boolean']['input']>;
  collaborator?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  expires?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isOnline?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  shop?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export enum GQLSortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

/** Directions for ordering a list of records. */
export enum GQLSortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type GQLTag = {
  __typename?: 'Tag';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: GQLTagName;
  notes: Maybe<Scalars['String']['output']>;
  private: Scalars['Boolean']['output'];
  remindedAt: Maybe<Scalars['DateTime']['output']>;
  reminderAt: Maybe<Scalars['DateTime']['output']>;
  translation: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum GQLTagName {
  New = 'new',
  NotVerified = 'notVerified'
}

export type GQLThread = {
  __typename?: 'Thread';
  id: Scalars['ID']['output'];
  messages: Array<GQLMessage>;
};

export type GQLTodo = {
  __typename?: 'Todo';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: GQLTodoSlug;
  updatedAt: Scalars['DateTime']['output'];
};

export enum GQLTodoSlug {
  VerifyEmailAddress = 'verifyEmailAddress'
}

export type GQLTokenUsage = {
  __typename?: 'TokenUsage';
  embeddingTokens: GQLTokenUsageItem;
  inputTokens: GQLTokenUsageItem;
  outputTokens: GQLTokenUsageItem;
};

export type GQLTokenUsageItem = {
  __typename?: 'TokenUsageItem';
  limit: Scalars['Int']['output'];
  percentageRemaining: Scalars['Float']['output'];
  percentageUsed: Scalars['Float']['output'];
  remaining: Scalars['Int']['output'];
  used: Scalars['Int']['output'];
};

export type GQLTooltip = {
  __typename?: 'Tooltip';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tour: GQLTour;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQLTour = {
  __typename?: 'Tour';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum GQLTrashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type GQLUser = {
  __typename?: 'User';
  assistants: Array<Maybe<GQLAssistant>>;
  createdAt: Scalars['DateTime']['output'];
  currentProject: Maybe<GQLProject>;
  customerId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  emailVerified: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isAbleTo: Array<Maybe<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  permissions: Array<Maybe<GQLPermission>>;
  projects: Array<GQLProject>;
  roles: Array<Maybe<GQLRole>>;
  tags: Array<Maybe<GQLTag>>;
  todoList: Array<Maybe<GQLTodo>>;
  tooltips: Array<Maybe<GQLTooltip>>;
  unreadNotifications: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};
