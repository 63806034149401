import { type AiChatAssistantProps } from "@/components/ai-chat-assistant/ai-chat-assistant";
import styles from "@/components/ai-chat-assistant/ai-chat-assistant.module.scss";
import { Message } from "@/components/ai-chat-assistant/message/message";
import { GQLMessageRole } from "@/utils/graphql.generated";
import { FunctionComponent } from "react";
import { useIntl } from "react-intl";

interface DemoMessagesProps extends Pick<AiChatAssistantProps, "demo"> {}

export const DemoMessages: FunctionComponent<DemoMessagesProps> = ({ demo }) => {
    const intl = useIntl();

    if (demo === "conversation") {
        return (
            <div className={styles.Messages}>
                <Message
                    message={intl.formatMessage({ id: "chat.demo.message1" })}
                    showFooter={true}
                    showAvatar={true}
                    role={GQLMessageRole.Assistant}
                    avatar={{
                        sizes: [
                            {
                                src: `${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/chat/avatar.png?tr=w-70,h-70`,
                                width: 70,
                                height: 70,
                            },
                        ],
                    }}
                />
                <Message
                    message={intl.formatMessage({ id: "chat.demo.message2" })}
                    showFooter={false}
                    showAvatar={false}
                    role={GQLMessageRole.User}
                />
                <Message
                    message={intl.formatMessage({ id: "chat.demo.message3" })}
                    showFooter={true}
                    showAvatar={true}
                    role={GQLMessageRole.Assistant}
                    avatar={{
                        sizes: [
                            {
                                src: `${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/chat/avatar.png?tr=w-70,h-70`,
                                width: 70,
                                height: 70,
                            },
                        ],
                    }}
                />
            </div>
        );
    }
    if (demo === "language-tone-informal") {
        return (
            <div className={styles.Messages}>
                <Message
                    message={intl.formatMessage({ id: "chat.demo.message4" })}
                    showFooter={true}
                    showAvatar={true}
                    role={GQLMessageRole.Assistant}
                    avatar={{
                        sizes: [
                            {
                                src: `${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/chat/avatar.png?tr=w-70,h-70`,
                                width: 70,
                                height: 70,
                            },
                        ],
                    }}
                />
                <Message
                    message={intl.formatMessage({ id: "chat.demo.message2" })}
                    showFooter={false}
                    showAvatar={false}
                    role={GQLMessageRole.User}
                />
                <Message
                    message={intl.formatMessage({ id: "chat.demo.message5" })}
                    showFooter={true}
                    showAvatar={true}
                    role={GQLMessageRole.Assistant}
                    avatar={{
                        sizes: [
                            {
                                src: `${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/chat/avatar.png?tr=w-70,h-70`,
                                width: 70,
                                height: 70,
                            },
                        ],
                    }}
                />
            </div>
        );
    }
    if (demo === "routing-order-return") {
        return (
            <div className={styles.Messages}>
                <Message
                    message={intl.formatMessage({ id: "chat.demo.message1" })}
                    showFooter={true}
                    showAvatar={true}
                    role={GQLMessageRole.Assistant}
                    avatar={{
                        sizes: [
                            {
                                src: `${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/chat/avatar.png?tr=w-70,h-70`,
                                width: 70,
                                height: 70,
                            },
                        ],
                    }}
                />
                <Message
                    message={intl.formatMessage({ id: "chat.demo.message6" })}
                    showFooter={false}
                    showAvatar={false}
                    role={GQLMessageRole.User}
                />
                <Message
                    message={intl.formatMessage({ id: "chat.demo.message7" })}
                    showFooter={true}
                    showAvatar={true}
                    role={GQLMessageRole.Assistant}
                    avatar={{
                        sizes: [
                            {
                                src: `${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/chat/avatar.png?tr=w-70,h-70`,
                                width: 70,
                                height: 70,
                            },
                        ],
                    }}
                />
            </div>
        );
    }
    if (demo === "feature-order-tracker") {
        return (
            <div className={styles.Messages}>
                <Message
                    message={intl.formatMessage({ id: "chat.demo.message1" })}
                    showFooter={true}
                    showAvatar={true}
                    role={GQLMessageRole.Assistant}
                    avatar={{
                        sizes: [
                            {
                                src: `${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/chat/avatar.png?tr=w-70,h-70`,
                                width: 70,
                                height: 70,
                            },
                        ],
                    }}
                />
                <Message
                    message={intl.formatMessage({ id: "chat.demo.message8" })}
                    showFooter={false}
                    showAvatar={false}
                    role={GQLMessageRole.User}
                />
                <Message
                    message={intl.formatMessage({ id: "chat.demo.message9" })}
                    showFooter={true}
                    showAvatar={true}
                    role={GQLMessageRole.Assistant}
                    avatar={{
                        sizes: [
                            {
                                src: `${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/chat/avatar.png?tr=w-70,h-70`,
                                width: 70,
                                height: 70,
                            },
                        ],
                    }}
                />
                <Message
                    message={intl.formatMessage({ id: "chat.demo.message10" })}
                    showFooter={false}
                    showAvatar={false}
                    role={GQLMessageRole.User}
                />
                <Message
                    message={intl.formatMessage({ id: "chat.demo.message11" })}
                    showFooter={true}
                    showAvatar={true}
                    role={GQLMessageRole.Assistant}
                    avatar={{
                        sizes: [
                            {
                                src: `${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/chat/avatar.png?tr=w-70,h-70`,
                                width: 70,
                                height: 70,
                            },
                        ],
                    }}
                />
            </div>
        );
    }
    return null;
};
