import {
    Button,
    IconChat,
    IconChevronDown,
    IconEdit,
    IconSend,
    InlineMessage,
    InputText,
    LazyLoad,
    Link,
    Spinner,
    useToast,
} from "@webomat/webomat-ui-react/build/esm";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "@/components/ai-chat-assistant/ai-chat-assistant.module.scss";
import { getWrapperClassByRenderMethod } from "@/components/ai-chat-assistant/ai-chat-assistant.utils";
import { Message } from "@/components/ai-chat-assistant/message/message";
import { NetworkStatus, useQuery } from "@apollo/client";
import { GQLThreadByIdMessageFragment, GQLThreadByIdQuery, GQLThreadByIdQueryVariables } from "@/queries/thread.generated";
import { THREAD_BY_ID_QUERY } from "@/queries/thread";
import { format } from "date-fns";
import { GQLAssistantIconPosition, GQLMessageRole } from "@/utils/graphql.generated";
import { useCreateMessageMutation } from "@/mutations/message";
import { usePollRun } from "./use-poll-run";
import { PUBLIC_ASSISTANT_BY_ID_QUERY } from "@/queries/assistant";
import { GQLPublicAssistantByIdQuery, GQLPublicAssistantByIdQueryVariables } from "@/queries/assistant.generated";
import { amtColor, hexToRGBA } from "@/utils/helper";
import { generateCSSFromKeyframes } from "@/utils/web-components";
import tailwindConfig from "../../tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";
import { useIntl } from "react-intl";
import { DemoMessages } from "@/components/ai-chat-assistant/demo-messages";

interface AiChatAssistantProps {
    id: string;
    color?: string;
    lang?: string;
    renderMethod?: "inline" | "button";
    buttonLabel?: string;
    position?: GQLAssistantIconPosition;
    demo?:
        | "conversation"
        | "language-tone-formal"
        | "language-tone-informal"
        | "routing-order-return"
        | "routing-fallback"
        | "service-order-status"
        | "feature-order-tracker";
    open?: boolean;
}

export const AiChatAssistant: FunctionComponent<AiChatAssistantProps> = ({ id, color, renderMethod, buttonLabel, position, demo, open }) => {
    const intl = useIntl();
    const fullConfig = resolveConfig(tailwindConfig);
    const { toast } = useToast();
    const bodyRef = useRef<HTMLDivElement>(null);
    const [started, setStarted] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(open || false);
    const [message, setMessage] = useState<string>("");
    const [lastMessage, setLastMessage] = useState<string>("");
    const [threadId, setThreadId] = useState<string | null>(localStorage.getItem("dbgAiChatAssistantThreadId"));
    const [cachedData, setCachedData] = useState<GQLThreadByIdQuery | null>(null);
    const { data, loading } = useQuery<GQLPublicAssistantByIdQuery, GQLPublicAssistantByIdQueryVariables>(PUBLIC_ASSISTANT_BY_ID_QUERY, {
        variables: {
            assistantId: id,
        },
    });
    const {
        data: dataThread,
        error: errorThread,
        networkStatus: networkStatusThread,
        refetch,
    } = useQuery<GQLThreadByIdQuery, GQLThreadByIdQueryVariables>(THREAD_BY_ID_QUERY, {
        variables: {
            id: threadId,
            assistantId: id,
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if (cachedData === null) {
                setCachedData(data);
            }
            if (data.thread?.id) {
                scrollToLastMessage();
                setThreadId(data.thread.id);
                localStorage.setItem("dbgAiChatAssistantThreadId", data.thread.id);
            }
        },
        skip: !isOpen && !started,
    });
    const {
        loading: loadingRun,
        error: errorRun,
        updateRunId,
        runId,
        scrollToLastMessage,
    } = usePollRun(id, threadId || "", threadId && isOpen ? true : false, bodyRef);
    const { createMessage, loading: loadingCreateMessage } = useCreateMessageMutation();
    const messages: GQLThreadByIdMessageFragment[] = [...(dataThread?.thread?.messages || [])].reverse();
    const isTyping = networkStatusThread === NetworkStatus.loading || loadingCreateMessage || loadingRun || runId ? true : false;
    const primaryColor = color ? color : data?.publicAssistant?.color || null;
    const iconPosition = position ? position : data?.publicAssistant?.position || "right";
    const predefinedMessages = [
        data?.publicAssistant?.predefinedMessage1,
        data?.publicAssistant?.predefinedMessage2,
        data?.publicAssistant?.predefinedMessage3,
        data?.publicAssistant?.predefinedMessage4,
        data?.publicAssistant?.predefinedMessage5,
    ].filter((item) => item);
    const finalButtonLabel = buttonLabel || data?.publicAssistant?.buttonLabel || undefined;
    const avatar = data?.publicAssistant?.avatar?.sizes?.[0]
        ? {
              sizes: [
                  {
                      src: data.publicAssistant.avatar.sizes[0].src,
                      width: data.publicAssistant.avatar.sizes[0].width,
                      height: data.publicAssistant.avatar.sizes[0].height,
                  },
              ],
          }
        : { sizes: [{ src: `${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/chat/default-avatar.svg?v2`, width: 100, height: 100 }] };

    useEffect(() => {
        scrollToLastMessage();
    }, [isTyping]);
    const keyFrames = generateCSSFromKeyframes(fullConfig.theme.keyframes, true);

    function handleCreateMessage(customMessage: string | null = null) {
        const finalMessage = customMessage || message;
        if (finalMessage.length > 1) {
            if (threadId) {
                setLastMessage(finalMessage);
                setMessage("");
                createMessage({
                    notifyOnNetworkStatusChange: true,
                    variables: {
                        threadId: threadId,
                        assistantId: id,
                        content: finalMessage,
                    },
                    update: (_, result) => {
                        if (result.errors && result.errors.length > 0) {
                            const message = result.errors.map((item) => {
                                return item.message;
                            })[0];
                            toast.error(message);
                        } else {
                            if (result.data?.createMessage?.message.run?.id) {
                                updateRunId(result.data.createMessage.message.run.id);
                            } else {
                                toast.error(intl.formatMessage({ id: "errors.general" }));
                            }
                        }
                    },
                });
            } else {
                toast.error(intl.formatMessage({ id: "errors.createMessageFailed" }));
            }
        } else {
            toast.error(intl.formatMessage({ id: "errors.messageToShort" }));
        }
    }

    function handleOpenChat() {
        setIsOpen(!isOpen);
        if (!started) {
            setStarted(true);
        }
    }

    function handleNewChat() {
        localStorage.removeItem("dbgAiChatAssistantThreadId");
        setThreadId(null);
        refetch();
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleCreateMessage();
        }
    }

    if (loading || data?.publicAssistant === null) return null;

    return (
        <>
            {primaryColor && (
                <style
                    dangerouslySetInnerHTML={{
                        __html: `.${styles.Wrapper}{--color-primary-100:${amtColor(primaryColor, 40)};--color-primary-200:${amtColor(
                            primaryColor,
                            30,
                        )};--color-primary-300:${amtColor(primaryColor, 20)};--color-primary-400:${amtColor(
                            primaryColor,
                            10,
                        )};--color-primary-500:${primaryColor};--color-primary-600:${amtColor(primaryColor, -10)};--color-primary-700:${amtColor(
                            primaryColor,
                            -20,
                        )};--color-primary-800:${amtColor(primaryColor, -30)};--color-primary-900:${amtColor(primaryColor, -40)};
                --shadows-button-primary-focus: 0 0 0 1px rgba(28, 30, 36, 0.05), 0 2px 5px 0 rgba(28, 30, 36, 0.05), 0 1px 2.5px 0 rgba(28, 30, 36, 0.05), 0 1px 2px 0 rgba(28, 30, 36, 0.05), ${hexToRGBA(
                    primaryColor,
                    30,
                )} 0px 0px 0px 4px;
                --shadows-button-primary: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, ${hexToRGBA(
                    primaryColor,
                    16,
                )} 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, ${hexToRGBA(primaryColor, 8)} 0px 2px 5px 0px;
                }`,
                    }}
                />
            )}
            <style
                dangerouslySetInnerHTML={{
                    __html: keyFrames,
                }}
            />
            <div
                className={`${styles.Wrapper} ${getWrapperClassByRenderMethod(renderMethod)} ${isOpen ? styles["Wrapper--open"] : ""} ${
                    styles[`Wrapper--icon-${iconPosition}`]
                } ${messages.length ? styles["Wrapper--with-messages"] : ""}`.trim()}
            >
                <div className={styles.Content}>
                    <div className={styles.Widget}>
                        <div className={styles.WidgetInner}>
                            <div className={styles.ScrollContainer}>
                                <div className={styles.Header}>
                                    <div className={styles.HeaderCrisp}>
                                        {demo ? (
                                            <div className={styles.HeaderCrispImage}>
                                                <LazyLoad
                                                    component="image"
                                                    imageProps={{
                                                        breakpoints: {
                                                            default: `${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/chat/avatar.png?tr=w-70,h-70`,
                                                        },
                                                    }}
                                                    width={70}
                                                    height={70}
                                                />
                                            </div>
                                        ) : (
                                            <div className={styles.HeaderCrispImage}>
                                                <LazyLoad
                                                    component="image"
                                                    imageProps={{
                                                        breakpoints: {
                                                            default: avatar.sizes[0].src,
                                                        },
                                                    }}
                                                    width={avatar.sizes[0].width}
                                                    height={avatar.sizes[0].height}
                                                />
                                            </div>
                                        )}
                                        <div className={styles.HeaderCrispBody}>
                                            <div className={styles.HeaderCrispTitle}>
                                                {demo
                                                    ? intl.formatMessage({ id: "chat.placeholder.title" })
                                                    : data?.publicAssistant?.title || intl.formatMessage({ id: "chat.placeholder.title" })}
                                            </div>
                                            <div className={styles.HeaderCrispText}>
                                                {demo
                                                    ? intl.formatMessage({ id: "chat.placeholder.subtitle" })
                                                    : data?.publicAssistant?.subtitle || intl.formatMessage({ id: "chat.placeholder.subtitle" })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.HeaderActions}>
                                        {!demo && (
                                            <Button
                                                leftAdornments={<IconEdit />}
                                                rounded="full"
                                                size="sm"
                                                clean={true}
                                                keepColor={true}
                                                onClick={handleNewChat}
                                            />
                                        )}
                                        <Button
                                            leftAdornments={<IconChevronDown />}
                                            rounded="full"
                                            size="sm"
                                            clean={true}
                                            keepColor={true}
                                            onClick={() => setIsOpen(!isOpen)}
                                        />
                                    </div>
                                </div>
                                <div className={styles.Body} ref={bodyRef}>
                                    {demo ? (
                                        <DemoMessages demo={demo} />
                                    ) : !dataThread ? (
                                        <div className={styles.Loading}>
                                            <Spinner size="lg" space="md" center={true} />
                                        </div>
                                    ) : errorThread || errorRun ? (
                                        <InlineMessage
                                            type="error"
                                            headline="Fehler"
                                            text={errorThread?.message || errorRun?.message || intl.formatMessage({ id: "errors.general" })}
                                        />
                                    ) : (
                                        <div className={styles.Messages}>
                                            <Message
                                                message={
                                                    data?.publicAssistant?.welcomeMessage ||
                                                    intl.formatMessage({ id: "chat.placeholder.welcomeMessage" })
                                                }
                                                showFooter={false}
                                                showAvatar={true}
                                                role={GQLMessageRole.Assistant}
                                                avatar={avatar}
                                            />
                                            {messages.length === 0 && lastMessage.length === 0 && predefinedMessages.length > 0 && (
                                                <>
                                                    {predefinedMessages.map((item, index) => (
                                                        <Message
                                                            message={item}
                                                            showFooter={false}
                                                            showAvatar={false}
                                                            role={GQLMessageRole.User}
                                                            onClick={() => handleCreateMessage(item)}
                                                            key={index}
                                                        />
                                                    ))}
                                                </>
                                            )}
                                            {messages.map((message, index) => (
                                                <Message
                                                    message={message.content?.[0]?.text?.value || "-"}
                                                    footer={format(message.createdAt, "HH:mm")}
                                                    showFooter={messages?.[index + 1]?.role === message.role ? false : true}
                                                    showAvatar={message.role === GQLMessageRole.Assistant}
                                                    role={message.role}
                                                    avatar={message.role === GQLMessageRole.Assistant ? avatar : null}
                                                    key={index}
                                                />
                                            ))}
                                            {isTyping && lastMessage && (
                                                <Message message={lastMessage} showFooter={false} showAvatar={false} role={GQLMessageRole.User} />
                                            )}
                                            {isTyping && (
                                                <Message isTyping={true} showFooter={false} role={GQLMessageRole.Assistant} avatar={avatar} />
                                            )}
                                        </div>
                                    )}
                                </div>
                                {dataThread && (
                                    <div className={styles.Form}>
                                        <div className={styles.FormInner}>
                                            <div className={styles.Input}>
                                                <InputText
                                                    placeholder={
                                                        data?.publicAssistant?.placeholder || intl.formatMessage({ id: "chat.placeholder.message" })
                                                    }
                                                    rounded="lg"
                                                    outline={false}
                                                    clean={true}
                                                    multiline={true}
                                                    minRows={1}
                                                    maxRows={4}
                                                    value={message}
                                                    onKeyDown={handleKeyDown}
                                                    onChange={(event) => setMessage(event.target.value)}
                                                    disabled={isTyping}
                                                />
                                            </div>
                                            <div className={styles.Submit}>
                                                <Button
                                                    rounded="full"
                                                    leftAdornments={<IconSend />}
                                                    color="primary"
                                                    disabled={isTyping || message.length === 0}
                                                    onClick={demo ? undefined : () => handleCreateMessage()}
                                                />
                                            </div>
                                        </div>
                                        {data?.publicAssistant.role.name === "FREE" && !demo && (
                                            <div className={styles.Branding}>
                                                <div className={styles.BrandingCell}>
                                                    <div className={styles.BrandingCellItem}>
                                                        <LazyLoad
                                                            component="image"
                                                            imageProps={{
                                                                breakpoints: {
                                                                    default: `${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/chat/ask-oskar-symbol.svg`,
                                                                },
                                                            }}
                                                            width={100}
                                                            height={100}
                                                        />
                                                    </div>
                                                    <div className={styles.BrandingCellItem}>
                                                        <Link href="https://www.askoskar.com" target="_blank">
                                                            ask Oskar
                                                        </Link>{" "}
                                                        by{" "}
                                                        <Link href="https://www.digital-brands-group.com" target="_blank">
                                                            DBG GmbH
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {renderMethod === "button" && (
                        <div className={styles.Button}>
                            <Button
                                size="lg"
                                label={finalButtonLabel}
                                leftAdornments={
                                    <div className={styles.ButtonIcon}>
                                        <IconChat />
                                        <IconChevronDown />
                                    </div>
                                }
                                rounded="full"
                                onClick={handleOpenChat}
                                shadow={true}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export { type AiChatAssistantProps };
