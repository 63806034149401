import { gql } from "@apollo/client";

const publicAssistantByIdAvatarFragment = gql`
    fragment PublicAssistantByIdAvatar on File {
        sizes(sizes: [{ width: 70, height: 70 }]) {
            src
            width
            height
        }
    }
`;

const publicAssistantByIdAssistantFragment = gql`
    fragment PublicAssistantByIdAssistant on Assistant {
        id
        name
        color
        position
        avatar {
            ...PublicAssistantByIdAvatar
        }
        welcomeMessage
        predefinedMessage1
        predefinedMessage2
        predefinedMessage3
        predefinedMessage4
        predefinedMessage5
        title
        subtitle
        placeholder
        buttonLabel
        role {
            name
        }
    }
    ${publicAssistantByIdAvatarFragment}
`;

export const PUBLIC_ASSISTANT_BY_ID_QUERY = gql`
    query PublicAssistantById($assistantId: String!) {
        publicAssistant(assistantId: $assistantId) {
            ...PublicAssistantByIdAssistant
        }
    }
    ${publicAssistantByIdAssistantFragment}
`;
